import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        <script type="text/plain" class="optanon-category-4" 
          dangerouslySetInnerHTML={{
            __html:
              "(function (d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s);\njs.id = id;\njs.src = \"//connect.facebook.net/pl_PL/all.js#xfbml=1&appId=654267651256301\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));"
          }}
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/site/framework/css/CheckboxSetField.css?m=1464882010"
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: "\nvar ThemeDir = 'themes/simple';\n"
          }}
        />
        
        <div className="addbg">
          <div className="wrapper produkty">
            <header
              style={{
                height: "120px"
              }}
            >
              <div
                style={{
                  height: "67px"
                }}
              >
                <h1 className="logo">
                  <a href="/" />
                </h1>
                <nav>
                  <a href="/site/produkty/" className="current">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/site/o-neutrogenie/kim-jestesmy/" className="link">
                    O Neutrogenie
                  </a>
                  <span>| </span>
                  <a href="/site/bad-piekna/" className="link">
                    Bądź piękna
                  </a>
                  <span>| </span>
                  <a href="/site/see-whats-possible/" className="link">
                    See What's Possible
                  </a>
                  <span>| </span>
                  <a href="/site/skora-z-niedoskonalosciami/" className="link">
                    Skóra z niedoskonałościami
                  </a>
                  <span>| </span>
                </nav>
              </div>
              <div
                style={{
                  "padding-left": "185px",
                  height: "53px"
                }}
              >
                <nav
                  className="second"
                  style={{
                    float: "none"
                  }}
                >
                  <a href="/site/hydroboost/" className="link">
                    Hydro Boost
                  </a>
                  <span>| </span>
                  <a href="/site/skin-detox/" className="link">
                    Skin Detox
                  </a>
                  <span>| </span>
                  <a href="/site/cellular-boost/" className="link">
                    Cellular Boost
                  </a>
                  <span />
                </nav>
              </div>
            </header>
            <div className="top">
              <h2>
                Produkty
                <br />
                NEUTROGENA <span>®</span>
              </h2>
              <h3>
                Wybierz produkt dostosowany do Twoich potrzeb, <br />
                korzystając z poniższych opcji.
              </h3>
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                Produkty
              </div>
            </div>
          
            <div className="content">
              <div className="filters">
                <p className="head">
                  Filtrowanie
                  <span />
                </p>
                <div className="filtrate">
                  <div className="middle">
                    <div className="aboutAnchor" />
                    <div>
                      
                        <p
                          id="Form_ProductsFilter_error"
                          className="message "
                          style={{
                            display: "none"
                          }}
                        />
                        <fieldset>
                          <div className="CompositeField composite nolabel ">
                            <div className="CompositeField composite nolabel ">
                              <div
                                id="Nice0"
                                className="field optionset checkboxset"
                              >
                                <label
                                  className="left"
                                  htmlFor="Form_ProductsFilter_Nice0"
                                >
                                  Przeznaczenie
                                </label>
                                <div className="middleColumn">
                                  <ul
                                    id="Form_ProductsFilter_Nice0"
                                    className="optionset checkboxset"
                                  >
                                    <li className="odd val6">
                                    <a htmlFor="Form_ProductsFilter_Nice0_6"
                                        className='custom-label' href='/produkty/do-ciała'>
                                        Do ciała
                                      </a>
                                    </li>
                                    <li className="even val7">
                                    <a htmlFor="Form_ProductsFilter_Nice0_7"
                                        className='custom-label' href='/produkty/do-rąk'>
                                        Do rąk
                                      </a>
                                    </li>
                                    <li className="odd val8">
                                    <a htmlFor="Form_ProductsFilter_Nice0_8"
                                        className='custom-label' href='/produkty/do-stóp'>
                                        Do stóp
                                      </a>
                                    </li>
                                    <li className="even val9">
                                    <a htmlFor="Form_ProductsFilter_Nice0_9"
                                        className='custom-label' href='/produkty/do-twarzy-i-ust'>
                                        Do twarzy i ust
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="CompositeField composite nolabel ">
                            <div className="CompositeField composite nolabel ">
                              <div
                                id="Nice1"
                                className="field optionset checkboxset"
                              >
                                <label
                                  className="left"
                                  htmlFor="Form_ProductsFilter_Nice1"
                                >
                                  Typ skóry
                                </label>
                                <div className="middleColumn">
                                  <ul
                                    id="Form_ProductsFilter_Nice1"
                                    className="optionset checkboxset"
                                  >
                                    <li className="odd val14">
                                    <a htmlFor="Form_ProductsFilter_Nice1_14"
                                        className='custom-label' href='/produkty/bardzo-sucha'>
                                        Bardzo sucha/szorstka{" "}
                                      </a>
                                    </li>
                                    <li className="even val15">
                                    <a htmlFor="Form_ProductsFilter_Nice1_15"
                                        className='custom-label' href='/produkty/sucha'>
                                        Sucha
                                      </a>
                                    </li>
                                    <li className="odd val16">
                                    <a htmlFor="Form_ProductsFilter_Nice1_16"
                                        className='custom-label' href='/produkty/normalna'>
                                        Normalna
                                      </a>
                                    </li>
                                    <li className="even val17">
                                    <a htmlFor="Form_ProductsFilter_Nice1_17"
                                        className='custom-label' href='/produkty/wrażliwa'>
                                        Wrażliwa
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="CompositeField composite nolabel ">
                            <div className="CompositeField composite nolabel ">
                              <div
                                id="Nice2"
                                className="field optionset checkboxset"
                              >
                                <label
                                  className="left"
                                  htmlFor="Form_ProductsFilter_Nice2"
                                >
                                  Zastosowanie
                                </label>
                                <div className="middleColumn">
                                  <ul
                                    id="Form_ProductsFilter_Nice2"
                                    className="optionset checkboxset"
                                  >
                                    <li className="odd val18">
                                    <a htmlFor="Form_ProductsFilter_Nice2_18"
                                        className='custom-label' href='/produkty/odżywianie'>
                                        Odżywianie
                                      </a>
                                    </li>
                                    <li className="even val19">
                                    <a htmlFor="Form_ProductsFilter_Nice2_19"
                                        className='custom-label' href='/produkty/oczyszczanie'>
                                        Oczyszczanie/ Nawilżanie
                                      </a>
                                    </li>
                                    <li className="odd val20">
                                    <a htmlFor="Form_ProductsFilter_Nice2_20"
                                        className='custom-label' href='/produkty/regeneracja'>
                                        <u>Regeneracja</u>
                                      </a>
                                    </li>
                                    <li className="even val69">
                                    <a htmlFor="Form_ProductsFilter_Nice2_69"
                                        className='custom-label' href='/produkty/elastyczność'>
                                        Elastyczność
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <input
                            type="hidden"
                            name="SecurityID"
                            defaultValue="9dc27fd9db0485813fdb68e79c9d70ac994d4fae"
                            className="hidden"
                            id="Form_ProductsFilter_SecurityID"
                          />
                          <div className="clear" />
                        </fieldset>                        
                    </div>
                  </div>
                </div>
              </div>            
              <div className="products">               
                <div className="row">
                <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/11/"
                  >
                    <img src="/site/assets/Uploads/36.png" alt="36" />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Intensywnie
                        Regenerująca emulsja do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/12/"
                  >
                    <img src="/site/assets/Uploads/37.png" alt="37" />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Intensywnie
                        Regenerująca emulsja do ciała SENSITIVE
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/19/"
                  >
                    <img src="/site/assets/Uploads/47.png" alt="47" />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Krem do rąk
                        (skoncentrowany)
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/20/"
                  >
                    <img src="/site/assets/Uploads/50.png" alt="50" />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup>&nbsp;Formuła Norweska Krem do rąk
                        bezzapachowy (skoncentrowany)
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/23/"
                  >
                    <img src="/site/assets/Uploads/53.png" alt="53" />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Krem do rąk
                        przeciw starzeniu się skóry
                      </p>
                      <p>&nbsp;</p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  </div>
                  <div className="row">
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/25/"
                  >
                    <img src="/site/assets/Uploads/54.png" alt="54" />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Intensywnie
                        regenerujący krem do rąk
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/26/"
                  >
                    <img src="/site/assets/Uploads/48.png" alt="48" />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Odżywczy krem do
                        stóp suchych i zniszczonych
                      </p>
                      <p>&nbsp;</p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/27/"
                  >
                    <img src="/site/assets/Uploads/56.png" alt="56" />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Krem do stóp na
                        popękane pięty
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/42/"
                  >
                    <img
                      src="/site/assets/Uploads/visibly-renew-krem-do-rak-3574661199887-PL.JPG"
                      alt="visibly renew krem do rak 3574661199887 PL"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup>&nbsp; Formuła Norweska Visibly
                        Renew Elasticity Boost - krem do rąk
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/43/"
                  >
                    <img
                      src="/site/assets/Uploads/visibly-renew-emulsja-do-ciala-3574661199283-PL.JPG"
                      alt="visibly renew emulsja do ciala 3574661199283 PL"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup>&nbsp; Formuła Norweska Visibly
                        Renew Elasticity Boost - emulsja do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  </div>
                <div className="row">
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/44/"
                  >
                    <img
                      src="/site/assets/Uploads/Packshoty-200-ml/balsam-do-ciala-visibly-renew.png"
                      alt="balsam do ciala visibly renew"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Visibly Renew
                        Elasticity Boost - balsam do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/64/"
                  >
                    <img
                      src="/site/assets/Uploads/Water-Gel-2.png"
                      alt="Water Gel 2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup> Water Gel
                        Nawadniający żel do cery normalnej i mieszanej
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/65/"
                  >
                    <img
                      src="/site/assets/Uploads/Gel-Cream-360.png"
                      alt="Gel Cream 360"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup> Gel-cream
                        Nawadniający żel-krem
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/67/"
                  >
                    <img
                      src="/site/assets/Uploads/zelowe-mleczko-nawilzajace-200ml.png"
                      alt="zelowe mleczko nawilzajace 200ml"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>{" "}
                        Nawadniające mleczko do twarzy
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/66/"
                  >
                    <img
                      src="/site/assets/Uploads/zel-do-mycia-twarzy-200ml.png"
                      alt="zel do mycia twarzy 200ml"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>{" "}
                        Nawadniający żel do mycia twarzy
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  </div>
                <div className="row">
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/68/"
                  >
                    <img
                      src="/site/assets/Uploads/HydroBoost-Peeling-150ml.png"
                      alt="HydroBoost Peeling 150ml"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>{" "}
                        Wygładzający peeling do twarzy
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/69/"
                  >
                    <img
                      src="/site/assets/Uploads/HydroBoost-CityShield-TUBE-FOP.png"
                      alt="HydroBoost CityShield TUBE FOP"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup> City
                        shield nawadniający krem do twarzy SPF 25
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/70/"
                  >
                    <img
                      src="/site/assets/Uploads/HydroBoost-EyeCream-TUBE-FOP.png"
                      alt="HydroBoost EyeCream TUBE FOP"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup> żel-krem
                        pod oczy przeciw oznakom zmęczenia
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/71/"
                  >
                    <img
                      src="/site/assets/Uploads/Hydro-Boost-Maska-na-noc.jpg"
                      alt="Hydro Boost Maska na noc"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>{" "}
                        Nawadniający krem-maska na noc
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/77/"
                  >
                    <img
                      src="/site/assets/Uploads/HydroBoost-Body-gel-250.png"
                      alt="HydroBoost Body gel 250"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> HydroBoost<sup>®</sup> Żelowy
                        Balsam do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  
                  </div>
                <div className="row">
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/78/"
                  >
                    <img
                      src="/site/assets/Uploads/HydroBoost-spray.png"
                      alt="HydroBoost spray"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> HydroBoost<sup>®</sup> Ekspresowy
                        spray do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/79/"
                  >
                    <img
                      src="/site/assets/Uploads/HydroBoost-whipped-balm.png"
                      alt="HydroBoost whipped balm"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> HydroBoost<sup>®</sup> Aksamitny
                        mus do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/80/"
                  >
                    <img
                      src="/site/assets/Uploads/HydroBoost-hand-cream-75ml.png"
                      alt="HydroBoost hand cream 75ml"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> HydroBoost<sup>®</sup> Żelowy
                        Krem do rąk
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                 
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/81/"
                  >
                    <img
                      src="/site/assets/Uploads/prod-2-4.png"
                      alt="prod 2 4"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup>{" "}
                        Nawadniający booster. Serum do twarzy
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/83/"
                  >
                    <img
                      src="/site/assets/Uploads/skin-detox-6.png"
                      alt="skin detox 6"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Skin Detox Oczyszczająca maska
                        2w1
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a> 
                  </div>
                  <div className="row">
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/87/"
                  >
                    <img
                      src="/site/assets/Uploads/Neutrogena-GB-Pho-Pac-18-1-12369232.png"
                      alt="Neutrogena GB Pho Pac 18 1 12369232"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Cellular Boost
                        Przeciwzmarszczkowy krem pod oczy
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_2 group2_2 group3_2"
                    href="/site/product/88/"
                  >
                    <img
                      src="/site/assets/Uploads/Concentrate.png"
                      alt="Concentrate"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Cellular Boost Koncentrat
                        przeciwzmarszczkowy
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>              
                                 
                </div>
              </div>
            </div>
            <div id="social_id_15"></div>
          </div>
        </div>
        <footer>
          <div className="wrapper">
            <nav>
              <a href="/site/" className="logo2" />
              <a href="/site/nota-prawna/">Nota Prawna</a>
              <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
               <a href="/site/cookies/">Polityka cookies</a>
              <a href="/site/kontakt/">Zachęcamy do kontaktu</a>
                           <span>© JNTL Consumer Health (Poland) sp. z o.o. 2023 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
            <p>
              © JNTL Consumer Health (Poland) sp. z o.o., 2023. Ostatnia aktualizacja 01.08.2023<br></br>
Strona zarządzana jest przez JNTL Consumer Health (Poland) sp. z o.o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS 0000937574, kapitał zakładowy w wysokości 107.357.450 PLN, NIP 5272980564. JNTL Consumer Health (Poland) sp. z o.o. ponosi wyłączną odpowiedzialność za treści zawarte na niniejszej stronie. Strona jest przeznaczona dla użytkowników z Polski.<br></br>
Niniejsza witryna zawiera łącza do witryn, które nie podlegają naszej polityce prywatności. Zachęcamy do zapoznawania się z politykami prywatności każdej odwiedzanej witryny.
              <br />{" "}
            </p>{" "}
            <p>
              <span>
                Strona została zoptymalizowana pod kątem przeglądarek: Internet
                Explorer 7 i wyższych, Mozilla Firefox, Google Chrome, Opera,
                Safari
              </span>{" "}
              <span className="right">
                Zalecana rozdzielczość: 1024 x 768 pikseli
              </span>{" "}
            </p>
          </div>
        </footer>
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
          }}
        />
        
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
          }}
        />
        
      </div>
    );
  }
}

export default Page;